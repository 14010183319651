import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { ChecklistItem } from '../../interfaces/checklist-item';
import { Observable } from 'rxjs';
import { FileUploader } from '../../utils/ng2-file-upload';
import { AuthService } from '../../../routes/user/auth.service';

@Injectable({
  providedIn: 'root'
})
export class ChecklistItemsBackendService {

  url = '/api/checklist-items';

  constructor(private http: HttpClient, private auth: AuthService) { }

  list(){
    return this.http.get<Array<ChecklistItem>>(this.url)
  }

  chapters(){
    return this.http.get<Array<ChecklistItem>>(`${this.url}/chapters`)
  }

  listTool(){
    return this.http.get<Array<ChecklistItem>>(`${this.url}/tool`)
  }

  listAll(){
    return this.http.get<Array<ChecklistItem>>(`${this.url}?show=all`)
  }

  create(data: ChecklistItem){
    return this.http.post(this.url, data);
  }

  update(data: ChecklistItem){
    return this.http.put(`${this.url}/${data._id}`, data);
  }

  delete(data: ChecklistItem){
    return this.http.delete(`${this.url}/${data._id}`);
  }

  uploadPicture = (checklistItem, files) =>{
    return new Observable((observer) => {
      const uploader = new FileUploader({
        url: `${this.url}/${checklistItem._id}/uploadPicture`,
        method: 'POST',
        authToken: 'Bearer ' + this.auth.getToken(),
        headers: [
          { name: 'Company', value: this.auth.getCompany() }
        ],
        autoUpload: true
      })

      uploader.onCompleteItem = (item, response, status) => {
        if (status > 400) {
          return observer.error(new HttpErrorResponse({error: response, status: status}))
        }
        observer.next(JSON.parse(response))
        observer.complete()
      }

      uploader.addToQueue(files)
    })
  }
}

